<template>
  <div class='mt-4 mr-4 ml-4'>
    <div>
      <b-card class='mb-4'>
        <SystemExecutionsList></SystemExecutionsList>
      </b-card>
    </div>
  </div>
</template>

<script>
import SystemExecutionsList from '@/components/SFTP/SystemExecutions/SystemExecutionsList.vue';

export default {
  name: 'SFTPSystemExecutions',
  components: {
    SystemExecutionsList,
  },
};
</script>
