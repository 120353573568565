<template>
  <div class='mt-4 mr-4 ml-4'>
    <h4>System Executions</h4>
    <b-row class='mb-4 mt-4'>
      <b-col cols='9'>
        <b-form-input
          id='filter-input'
          v-model='filepath'
          type='search'
          placeholder='Select filepath'>
        </b-form-input>
      </b-col>
      <b-col cols='3'>
        <b-button
          variant='outline-primary'
          @click.prevent='searchEvent'
          :disabled='loading'>Search
        </b-button>
      </b-col>
    </b-row>
    <div v-if='loading'>
      <b-row>
        <b-col cols='12'>
          <b-spinner></b-spinner>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-table
        id='system-executions-table'
        striped
        hover
        :items='items'
        :fields='fields'
        @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)">
        <template #cell(last_attempt)='row'>
          {{ parseInt(row.item.last_attempt) + 1 }}
        </template>
        <template #cell(created_at)='row'>
          {{ normalizeDate(row.item.created_at) }}
        </template>
        <template #cell(updated_at)='row'>
          {{ normalizeDate(row.item.updated_at) }}
        </template>
        <template #cell(finished_at)='row'>
          {{ normalizeDate(row.item.finished_at) }}
        </template>
        <template #cell(actions)='row'>
          <router-link
            :to="{ name: 'SFTPSystemExecutionAttempts', params: { uuid: row.item.uuid }}"
            class="btn btn-primary"
            target="_blank"
            size="sm">
            Show Attempts
          </router-link>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model='currentPage'
      :total-rows='rows'
      :per-page='perPage'
      aria-controls='system-executions-table'
      @change='updatePage'>
    </b-pagination>
  </div>
</template>

<script>
import moment from 'moment/moment';

import { isNullOrEmpty } from '@/helpers';

export default {
  name: 'SystemExecutionsList',
  data() {
    return {
      filepath: null,
      selectedUuid: null,
      selectedAttempt: null,
      currentPage: 1,
      rows: 0,
      perPage: 10,
      loading: true,
      items: [],
      fields: [
        { key: 'last_attempt', label: 'Attempt' },
        { key: 'filepath', label: 'Filepath' },
        { key: 'type', label: 'Type' },
        { key: 'status', label: 'Status' },
        { key: 'created_at', label: 'Created At' },
        { key: 'updated_at', label: 'Updated At' },
        { key: 'finished_at', label: 'Finished At' },
        { key: 'actions', label: 'Actions' },
      ],
      offset: 0,
      limit: 10,
      modal: {
        open: false,
      },
    };
  },
  async beforeMount() {
    await this.getExecutions(this.offset, this.limit);
  },
  methods: {
    getExecutions(offset, limit) {
      this.loading = true;
      return this.$store.dispatch('Sftp/SystemExecutions/getSystemExecutions', {
        offset,
        limit,
        filepath: this.filepath,
      })
        .then(({ data: { executions, count } }) => {
          this.items = executions;
          this.rows = count;
        })
        .catch(e => {
          this.$noty.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    normalizeDate(date) {
      if (isNullOrEmpty(date)) return '-';
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    async updatePage(event) {
      this.offset = event * this.perPage - this.perPage;
      await this.getExecutions(this.offset, this.limit);
    },
    searchEvent() {
      return this.getExecutions(this.offset, this.limit);
    },
  },
};

</script>
